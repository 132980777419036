export default {
  colors: {
    primary: '#4f8ef5',
    background: '#fff',
    sidebar: {
      background: '#ffffff',
      link: '#999',
      heading: '#aaa',
      linkActive: '#13131A',
      itemActive: 'rgba(79, 142, 245, 0.1)',
      footer: '#A8A8B3',
    },
  },
};
